<template>
  <header class="l-header">
    <div class="l-header-inner">
      <div class="l-header-left">
        <NuxtLink to="/" active-class="active" class="c-hover">
          <img
            src="@/assets/img/logo.png"
            alt=""
            style="height: 25px"
            :class="{ animation: isAnimating }"
          />
        </NuxtLink>
      </div>

      <div class="l-header-right">
        <span class="l-header-icon">
          <img
            src="@/assets/svg/hamburger.svg"
            class="l-header-left-menu c-hover"
            @click="useSettingStore().toggleMenu()"
          />
          <!-- 引き換え可能時のアイコン -->
          <font-awesome-icon
            v-if="isNotsendIcon"
            :icon="['fas', 'circle-exclamation']"
          />
        </span>
        <ul class="links">
          <li>
            <NuxtLink to="/" active-class="active">
              <span>くじを探す</span>
            </NuxtLink>
          </li>
          <li
            v-if="isLogin"
            :class="{ isShow: isMiniMenuOpen }"
            @mouseover="onMouseOverMenu()"
            @mouseleave="onMouseLeaveMenu()"
            @click="onClickToggleMiniMenu()"
          >
            <NuxtLink to="/shipping/pending">
              <font-awesome-icon icon="angle-down" />
              <span>引き換え可能</span>
            </NuxtLink>
            <!-- 引き換え可能時のアイコン -->
            <font-awesome-icon
              v-if="isNotsendIcon"
              :icon="['fas', 'circle-exclamation']"
            />
          </li>
          <li v-if="isLogin">
            <NuxtLink to="/mypage/">
              <span>マイページ</span>
            </NuxtLink>
          </li>
          <li v-if="!isLogin">
            <NuxtLink to="/auth/signup">
              <span>新規会員登録</span>
            </NuxtLink>
          </li>
          <li>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdYfrSHO6-1xF7kezFmtC6VAMzlaIuatZICrxSbXynk4ZIIIA/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <span>お問い合わせ</span>
            </a>
          </li>
        </ul>
      </div>

      <div
        v-if="isMenuOpen"
        class="menu-list-overlay"
        @click="isMenuOpen = !isMenuOpen"
      />
    </div>
  </header>

  <!-- 引き換え可能のマウスオーバーメニュー用 -->
  <div class="l-header-dummy">
    <div class="l-header-inner">
      <div class="l-header-left">
        <NuxtLink to="/" active-class="active" class="c-hover">
          <img
            src="@/assets/img/logo.png"
            alt=""
            style="height: 25px"
            :class="{ animation: isAnimating }"
          />
        </NuxtLink>
      </div>

      <div class="l-header-right">
        <ul class="links">
          <li>
            <a href="/">
              <span>くじを探す</span>
            </a>
          </li>
          <li v-if="isLogin" :class="{ isShow: isMiniMenuOpen }">
            <a>
              <font-awesome-icon icon="angle-down" />
              <span>引き換え可能</span>
            </a>
            <!-- 引き換え可能時のアイコン -->
            <font-awesome-icon
              v-if="true"
              :icon="['fas', 'circle-exclamation']"
            />
            <div
              class="menu-mini"
              :class="{ 'menu-mini--show': isMiniMenuOpen }"
              @mouseover="isMiniMenuOpen = true"
            >
              <ul>
                <li @click="isMiniMenuOpen = false">
                  <NuxtLink class="c-hover" to="/shipping/requested">
                    配送依頼済み
                  </NuxtLink>
                </li>
                <li @click="isMiniMenuOpen = false">
                  <NuxtLink class="c-hover" to="/shipping/sent">
                    発送済み
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="isLogin">
            <a href="/mypage/">
              <span>マイページ</span>
            </a>
          </li>
          <li v-if="!isLogin">
            <a href="/auth/signup">
              <span>新規会員登録</span>
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdYfrSHO6-1xF7kezFmtC6VAMzlaIuatZICrxSbXynk4ZIIIA/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <span>お問い合わせ</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * data
 */
// メニューを開くかどうか
const isMenuOpen = ref(false)
// マウスオーバーメニューを開くかどうか
const isMiniMenuOpen = ref(false)
const isMenuClosedByClick = ref(false)
const isAnimating = ref(false)

/**
 * computed
 */
const isLogin = computed(() => useUserStore().isLogin)
const isNotsendIcon = computed(() => useUserRewordStore().notsendCount > 0)

/**
 * methods
 */
function onMouseOverMenu() {
  if (isMenuClosedByClick.value) {
    isMenuClosedByClick.value = false
    return
  }
  isMiniMenuOpen.value = true
}
function onMouseLeaveMenu() {
  isMiniMenuOpen.value = false
}
function onClickToggleMiniMenu() {
  isMiniMenuOpen.value = false
  isMenuClosedByClick.value = true
}

/**
 * 初期化
 */
if (import.meta.client) {
  setInterval(() => {
    isAnimating.value = true
    setTimeout(() => {
      isAnimating.value = false
    }, 3000)
  }, 30000)
}
</script>

<style lang="scss" scoped>
.l-header {
  position: fixed;
  top: 0;
  z-index: $header-z-index;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #c9ccd4;

  &-dummy {
    z-index: calc($header-z-index - 5);
    position: fixed;
    width: 100%;
    top: 0;
  }
  @include media-sp-tb {
    width: 100%;
  }

  @include media-sp {
    border: none;
  }

  &-icon {
    position: relative;
    svg {
      position: absolute !important;
      top: -3px !important;
      right: 3px !important;
      @include media-pc-tb() {
        display: none;
      }
    }
  }

  &-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: $header-height;
    @include media-sp {
      height: $sp-header-height;
    }
  }

  &-left,
  &-right {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &-left {
    padding-left: 20px;
    a {
      height: 100%;
      display: flex;
      align-items: center;
    }
    img.animation {
      animation: purupuru_anim 0.6s infinite alternate;
    }

    &-menu {
      width: 46px;
      cursor: pointer;
      vertical-align: bottom;

      @include hover() {
        opacity: 0.5;
      }

      @include media-pc-tb() {
        display: none;
      }
    }

    &-title {
      width: 100%;
      font-weight: bold;
    }
  }

  &-right {
    padding-right: 20px;
  }
}

.svg-inline--fa.fa-circle-exclamation {
  color: #fa2f2f;
  font-size: 21px;
  position: absolute;
  right: 0;
  top: 35%;
  transform: translateX(50%);
}

.links {
  display: flex;
  height: 100%;
  gap: 0 20px;
  @include media-tb {
    gap: 0 8px;
  }
  @include media-sp {
    display: none;
  }
  > li {
    display: flex;
    height: 100%;
    align-items: center;
    $hover-color: $color;
    padding-right: 6px;
    position: relative;
    max-width: 146px;
    box-sizing: border-box;
    padding: 0 17px;
    @include media-tb {
      padding: 0 20px;
    }
    &.isShow {
      box-shadow: 0 0 0 1px #fff;
    }

    > a,
    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      @include hover() {
        opacity: 0.6;
        cursor: pointer;
        transition: opacity 0.3s;
        > svg {
          fill: $hover-color;
          rect,
          rect + path {
            stroke: $hover-color;
          }
        }
      }
    }

    > a {
      color: #000;
      font-size: 1.5rem;
      font-weight: 700;
      position: relative;
      > svg {
        margin-right: 8px;
        fill: #b2b2b2;
      }
    }

    &.nomargin {
      margin-right: 0;
    }

    .icon {
      padding: 8px;

      @include media-sp() {
        width: 44px;
      }

      > svg {
        margin-right: 0;
      }
    }

    @include media-sp-tb() {
      margin-right: 0;
      > a {
        font-size: 1.4rem;
        // > svg {
        // 	margin-right: 0;
        // 	width: 80%;
        // 	height: 80%;
        // }
      }
      .icon {
        > svg {
          width: 100%;
          height: 100%;
        }
      }
      .feed {
        > svg {
          width: 70%;
          height: 70%;
        }
      }
    }

    .icon {
      padding-right: 16px;
      > span {
        position: absolute;
        right: 4px;
        top: 8px;
        display: inline-block;
        padding: 0 5px;
        background-color: #c72b4a;
        border-radius: 8px;
        color: #fff;
        &:empty {
          display: none;
        }
      }

      @include media-sp-tb() {
        padding: 8px;
      }
    }

    .user {
      width: 44px;
      height: 44px;
      padding: 0;
      border-radius: 4px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }
      @include hover() {
        opacity: 0.6;
      }
      &.is-blank {
        padding: 8px;
        background-color: #e0e0e0;
        line-height: 0;
        > svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .menu {
      position: relative;
      width: 48px;
      height: 100%;
      border-left: 1px solid #efefef;
      @include hover() {
        background-color: rgba(#eee, 0.3);
      }
      > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 24px;
        height: 2px;
        //background-color: #69BEB6;
        background-color: #f8ae17;
        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 2px;
          //background-color: #69BEB6;
          background-color: $color;
        }
        &::before {
          top: -8px;
        }
        &::after {
          top: 8px;
        }
      }
      &.is-open {
        > span {
          width: 26px;
          background-color: transparent;
          &::before,
          &::after {
            top: 0;
          }
          &::before {
            transform: rotate(45deg);
          }
          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

.menu-list-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: $menu-overlay-z-index;
}

.menu-list {
  position: fixed;
  z-index: $menu-z-index;
  top: $header-height;
  padding: 2px 0;

  &-inner {
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar-track {
      box-shadow: inherit !important;
    }
  }

  .title {
    padding: 12px 20px;
    font-size: 1.6rem;
    font-weight: 600;
    //color: #69BEB6;
    color: $color;
    > span {
      font-size: 1.5rem;
      font-weight: 300;
      margin-left: 12px;
    }
  }

  $icon-padding: 4px 0;

  .main,
  .sub {
    > li {
      > a,
      button {
        display: flex;
        width: 100%;
        padding: 12px 20px 12px 16px;
        align-items: center;
        font-size: 1.5rem;
        @include hover() {
          background-color: rgba($color, 0.1);
        }
        > svg {
          width: 60px;
          padding: $icon-padding;
          transform: scale(1.4, 1.4);
        }
        @include media-sp-tb() {
          font-size: 1.6rem;
        }
      }
    }
  }
  .main {
    > li {
      > a,
      > button {
        color: $color;
        > svg {
          fill: #f8ae17;
        }
      }
    }
  }

  .sub {
    > li {
      > a,
      button {
        color: #767676;
        > svg {
          fill: $color-deep;
        }
      }
    }
  }

  .logout {
    > svg {
      height: 26px;
    }
  }

  @include media-pc() {
    right: 24px;
    width: 280px;
    margin-top: 16px;
    background-color: #fff;
    box-shadow: 0 2px 5px 2px rgba(200, 200, 200, 1);
    border-radius: 4px;

    &-inner {
      max-height: calc(100vh - 170px);
    }

    &::before {
      $size: 20px;
      position: absolute;
      right: 28px;
      top: -16px;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12px 16px 12px;
      border-color: transparent transparent #fff transparent;
    }

    .main,
    .sub {
      > li {
        > a,
        button {
          > svg {
            width: 60px;
            padding: $icon-padding;
          }
        }
      }
    }
  }

  @include media-sp-tb() {
    left: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;

    .main,
    .sub {
      > li {
        > a {
          padding: 12px 20px 12px 4;
          > svg {
            width: 60px;
            padding: $icon-padding;
          }
        }
      }
    }

    .main {
      margin: 8px 0 12px;
    }
  }
}

.menu-mini {
  position: absolute;
  width: 148px;
  height: 87px;
  background: #fff;
  border: 1px solid #c9ccd4;
  border-radius: 0 0 8px 8px;
  bottom: 0%;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  border-top: none;
  transition: 0.3s ease;
  &--show {
    bottom: -87px;
  }

  a {
    color: #000;
    font-size: 13px;
    padding: 12px 21px;
    display: inline-block;
    width: 100%;
    font-weight: 700;
    box-sizing: border-box;
  }
}
@keyframes purupuru_anim {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(-5px, -0);
  }
  10% {
    transform: translate(5px, 0);
  }
  15% {
    transform: translate(-5px, -0);
  }
  20% {
    transform: translate(5px, 0);
  }
  25% {
    transform: translate(-5px, -0);
  }
  30% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
